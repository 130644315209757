import { Button, DialogContent, Dialog, Form, DialogTrigger, Heading } from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { createSignal, For } from 'solid-js';
import { Title } from '@solidjs/meta';
import { gql, mutationAction, useMutation } from '../../graphql';
import { AddCard } from '../../partials/add-card';
import { cardBrandToComponent, cardBrandToString } from '../../modules/credit-cards';
import { cachedQuery } from '../../graphql/cached-get';

export default function PaymentMethods() {
	const data = createAsync(() => getPaymentMethods({}));
	const removeCardAction = useMutation(removeCard);
	const [addOpen, setAddOpen] = createSignal(false);

	return (
		<>
			<Title>Payment methods | My account | Troon</Title>
			<Heading as="h1">Payment Methods</Heading>

			<ul class="divide-y divide-neutral-500 rounded border border-neutral p-6">
				<For
					each={data()?.creditCards}
					fallback={<li class="text-center italic text-neutral-700">No payment methods available.</li>}
				>
					{(card) => {
						const CardIcon = cardBrandToComponent[card.brand];
						return (
							<li class="flex flex-row items-center gap-2 py-4">
								<div class="w-10">
									<CardIcon />
								</div>
								<div class="grow">
									{cardBrandToString[card.brand]} ending in {card.lastFour}
								</div>
								<Form action={removeCardAction} document={removeCardMutation} method="post">
									<input type="hidden" name="cardId" value={card.id} />
									<Button appearance="secondary" action="danger" type="submit">
										Remove
									</Button>
								</Form>
							</li>
						);
					}}
				</For>
			</ul>

			<Dialog key="add-payment-method" open={addOpen()} onOpenChange={setAddOpen}>
				<DialogTrigger appearance="secondary" class="w-fit">
					Add new payment method
				</DialogTrigger>
				<DialogContent header="Add payment method" headerLevel="h2">
					<AddCard
						onCancel={() => setAddOpen(false)}
						onSuccess={() => {
							setAddOpen(false);
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
}

const query = gql(`
query paymentMethods {
	creditCards {
		id
		lastFour
		brand
	}
}`);

const getPaymentMethods = cachedQuery(query);

const removeCardMutation = gql(`
mutation removeCreditCard($cardId: String!) {
	removeCreditCard(cardId: $cardId) {
		ok
	}
}`);

const removeCard = mutationAction(
	removeCardMutation,
	{
		revalidates: ['paymentMethods'],
	},
	{ additionalTypenames: ['CreditCard'] },
);
